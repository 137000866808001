<template>
  <div class="text-primary text-3xl text-center py-7 border-b border-grey-fp-30">Toggles</div>
  <div class="h-full flex justify-around w-full h-full">
    <!-- S I M P L E  T O G G L E -->
    <div class="flex flex-col justify-center items-center w-1/2">
      <div>
        <div class="flex items-center mb-20">
          <AppToggle v-model="firstToggler" class="mr-30" />
          <span class="text-1xl text-bold">{{ firstToggler ? 'ON' : 'OFF' }}</span>
        </div>

        <div class="flex items-center mb-20">
          <AppToggle v-model="firstDisabledToggler" disabled class="mr-30" />
          <span class="text-1xl text-bold">DISABLED ON</span>
        </div>

        <div class="flex items-center">
          <AppToggle v-model="secondDisabledToggler" disabled class="mr-30" />
          <span class="text-1xl text-bold">DISABLED OFF</span>
        </div>
      </div>
    </div>

    <!-- D I V I D E R -->
    <div class="border-r border-grey-fp-30" />

    <!-- B U T T O N  T O G G L E -->
    <div class="flex flex-col justify-center items-center w-1/2">
      <div>
        <div class="flex items-center mb-20">
          <AppButtonToggle v-model="firstToggleButtonValue" :items="toggleOptions" class="mr-30" />
          <div>
            <span class="text-1xl text-grey-fp-60 mr-20">Selected value:</span> 
            <span class="text-1xl text-bold">{{ firstToggleButtonValue.label }}</span>
          </div>
        </div>

        <div class="flex items-center mb-20">
          <AppButtonToggle v-model="secondToggleButtonValue" :items="toggleOptions" disabled class="mr-30" />
          <span class="text-1xl text-bold">DISABLED </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, toRefs, reactive } from 'vue';

import AppToggle from '@/components/stateless/AppToggle.vue';
import AppButtonToggle from '@/components/stateless/AppButtonToggle.vue';
import { IOption } from '@/types';

export default defineComponent({
  name: 'Tabs',

  components: { AppToggle, AppButtonToggle },

  setup() {
    const firstToggler = ref<boolean>(false);
    const firstDisabledToggler = ref<boolean>(true);
    const secondDisabledToggler = ref<boolean>(false);

    const toggleOptions: IOption[] = [
      { value: 'AM', label: 'AM' },
      { value: 'PM', label: 'PM'}
    ];

    const form = reactive({ firstToggleButtonValue: {}, secondToggleButtonValue: {}});

    const { firstToggleButtonValue, secondToggleButtonValue } = toRefs(form);

    return { 
      firstToggler,
      firstDisabledToggler, 
      secondDisabledToggler, 
      toggleOptions, 
      firstToggleButtonValue,
      secondToggleButtonValue
    };
  }

});
</script>