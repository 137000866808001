
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppToggle',

  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    
    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {

    function toggle () {
      if (!props.disabled) {
        emit('update:modelValue', !props.modelValue);
      }
    }
    
    return { toggle };
  }
});
