
import { defineComponent, onBeforeMount, PropType } from 'vue';

import { IOption } from '@/types';

export default defineComponent({
  name: 'AppToggle',

  props: {
    modelValue: {
      type: Object as PropType<IOption>,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    },
    
    items: {
      type: Array as PropType<IOption[]>,
      required: true,
      validator: (val: IOption[]) => val.length === 2
    }
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {

    function toggle (value: IOption) {
      if (!props.disabled) {
        emit('update:modelValue', value);
      }
    }

    function preselectValue () {
      if (!Object.keys(props.modelValue).length) {
        emit('update:modelValue', props.items[0]);
      }
    }

    onBeforeMount(() => {
      preselectValue();
    });

    return { toggle };
  }
});
