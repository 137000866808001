<template>
  <div class="flex items-center">
    <div 
      class="w-48 p-2 rounded-12 cursor-pointer"
      :class="[!modelValue ? 'bg-grey-fp-40' : 'bg-primary', { disabled }]" 
      @click.stop="toggle"
    >
      <div
        class="checkbox-switch w-20 h-20 rounded-full cursor-pointer bg-white"
        :class="{ 'is-checked': modelValue }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppToggle',

  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    
    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {

    function toggle () {
      if (!props.disabled) {
        emit('update:modelValue', !props.modelValue);
      }
    }
    
    return { toggle };
  }
});
</script>


<style lang="scss" scoped>
.checkbox-switch {
  transition: all 350ms;
  background-blend-mode: overlay, normal;

  &.is-checked {
    transform: translateX(24px);
  }
}

.disabled {
  @apply opacity-20;
}

</style>
