
import { defineComponent, ref, toRefs, reactive } from 'vue';

import AppToggle from '@/components/stateless/AppToggle.vue';
import AppButtonToggle from '@/components/stateless/AppButtonToggle.vue';
import { IOption } from '@/types';

export default defineComponent({
  name: 'Tabs',

  components: { AppToggle, AppButtonToggle },

  setup() {
    const firstToggler = ref<boolean>(false);
    const firstDisabledToggler = ref<boolean>(true);
    const secondDisabledToggler = ref<boolean>(false);

    const toggleOptions: IOption[] = [
      { value: 'AM', label: 'AM' },
      { value: 'PM', label: 'PM'}
    ];

    const form = reactive({ firstToggleButtonValue: {}, secondToggleButtonValue: {}});

    const { firstToggleButtonValue, secondToggleButtonValue } = toRefs(form);

    return { 
      firstToggler,
      firstDisabledToggler, 
      secondDisabledToggler, 
      toggleOptions, 
      firstToggleButtonValue,
      secondToggleButtonValue
    };
  }

});
