<template>
  <div 
    class="toggle-button flex items-center border border-grey-fp-50 rounded-5 text-md" 
    :class="{ 'disabled': disabled }"
  >
    <div 
      v-for="item of items" 
      :key="item.value" 
      class="item cursor-pointer px-18 py-8 text-dark-cl-20"
      :class="{'active': item.value === modelValue.value }"
      @click="toggle(item)"
    >
      <span>
        {{ item.label }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, PropType } from 'vue';

import { IOption } from '@/types';

export default defineComponent({
  name: 'AppToggle',

  props: {
    modelValue: {
      type: Object as PropType<IOption>,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    },
    
    items: {
      type: Array as PropType<IOption[]>,
      required: true,
      validator: (val: IOption[]) => val.length === 2
    }
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {

    function toggle (value: IOption) {
      if (!props.disabled) {
        emit('update:modelValue', value);
      }
    }

    function preselectValue () {
      if (!Object.keys(props.modelValue).length) {
        emit('update:modelValue', props.items[0]);
      }
    }

    onBeforeMount(() => {
      preselectValue();
    });

    return { toggle };
  }
});
</script>


<style lang="scss" scoped>
.toggle-button {

  .item {
    transition: all 0.3s;

    &:hover:not(.active){
      @apply bg-blue-fp-20;
    }

    &.active {
      @apply bg-primary text-white;
    }

    &:first-child {
      @apply rounded-l-4;
    }

    &:last-child {
      @apply rounded-r-4;
    }
  }

  &.disabled {
    @apply opacity-20;
  }
}

</style>
